.contact-card {
    border: solid 2pt;
    border-radius: 5px;
    margin: 2px;

    .contact-card-title {
        background-color: black;
        color: white;
        font-size: 0.8em;
        padding-left: 5px;
    }

    .contact-card-body {
        margin: 3px;
    }

    .contact-job-title {
        font-size: 0.7em;
        color: rgb(36, 36, 36);
        padding-left: 5px;
    }
}

.authorized-contact-card > .contact-card {
    border-color: $contact-color-authorized;

    .contact-card-title {
        background-color: $contact-color-authorized;
    }
}

.site-contact-card > .contact-card {
    border-color: $contact-color-site;

    .contact-card-title {
        background-color: $contact-color-site;
    }
}
