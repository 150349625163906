.contract-terms-info {
    display: flex;
    justify-content: center;

    table {
        tr {
            td:nth-child(1) {
                padding-right: 5px;
            }
            td:nth-child(2) {
                font-weight: 600;
            }
        }
    }
}

.agreement-image-offcanvas {
    background-color: black;

    .offcanvas-header {
        color: white;
    }

    &.offcanvas-end {
        width: 80%;
    }
}

.entity-edit-offcanvas {
    &.offcanvas-end {
        min-width: 90%;

        @include media-breakpoint-up(lg) {
            min-width: 800px;
        }
    }
}

.client-contact-assignment {
    max-height: 300px;
    overflow: auto;
}
