.selectable-address {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;

    &:hover {
        color: white;
        cursor: pointer;
    }
}
