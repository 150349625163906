.sidebar {
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    background-color: $primary;
    transition: width 0.5s;
    color: #ffff;

    .sidebar-header {
        display: flex;
        align-items: center;
        padding: 16px;
        padding-bottom: 24px;
    }

    .sidebar-items {
        .item {
            height: 16px;
            text-decoration: none;
            color: #ffff;
            .sidebar-text {
                white-space: nowrap;
            }
        }
    }

    &.collapsed {
        width: 52px;

        .sidebar-icon {
            margin-right: 0;
        }

        .sidebar-text {
            display: none;
        }
    }
}

.sidebar-header {
    font-size: 16px;
    cursor: pointer;
}

.sidebar-icon {
    margin-right: 16px;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    .item {
        display: flex;
        align-items: center;
        padding: 16px 8px;
        border-radius: 5px;
        transition: background-color 0.2s;
        cursor: pointer;

        &:hover {
            background-color: lighten($primary, 10%);
        }
    }
}
